/* eslint-disable indent */
import React, { useState } from "react"
import * as shortid from "shortid"
import PropTypes from "prop-types"

import { Link, graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import ButtonBase from "@material-ui/core/ButtonBase"
import Hidden from "@material-ui/core/Hidden"
import Collapse from "@material-ui/core/Collapse"

import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"
import VerticalGallery from "../components/carousels/VerticalGallery"
import ProceduresCarousel from "../components/carousels/ProceduresCarousel"
import TeamMembersCarousel from "../components/carousels/TeamMembersCarousel"
import HelpersCarousel from "../components/carousels/HelpersCarousel"
import SchedulerContact from "../components/contacts/SchedulerContact"
import LocationMapCard from "../components/cards/LocationMapCard"
import ContactPopup from "../components/contacts/ContactPopup"
import ContactLocationCard from "../components/cards/ContactLocationCard"

import addToSchema from "../services/addToSchema"
import { localizedSlugPrefix } from "../gatsby/nodeHelpers"

import logoANCMS from "../img/logo_anmcs.jpg"
import logoCNAS from "../img/logo_cnas.png"

import ArrowDownIcon from "../components/icons/ArrowDownIcon"
import ArrowUpIcon from "../components/icons/ArrowUpIcon"
import Embed from "../components/Embed"
import ContactBanner from "../components/banners/ContactBanner"

const useStyles = makeStyles(theme => ({
  title: {
    background: "#065ea6",
    color: "#fff",
    fontWeight: "400",
  },
  logosWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  accordion: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },
  buttonWrapper: {
    marginBottom: 5,
  },

  icon: {
    marginRight: 15,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center left",
    color: "transparent",
    height: 60,
    width: 60,
  },

  headingText: {
    color: "#fff",
    fontWeight: 600,
    textAlign: "left",
    fontSize: '1.2rem'
  },
  fiscalDescription: {
    color: '#98999a',
    backgroundColor: '#e6e7e9',
    textAlign: 'center',
    padding: theme.spacing(2),

    "& strong": {
      fontWeight: 700
    }
  },
  guideList: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
}))

const getSchema = (
  contactLocation,
  procedures,
  teamMemberList,
  teamMemberPage
) => {
  let schema = contactLocation.data.schema_json.text || ""

  schema = addToSchema(schema, {
    "@context": "https://schema.org",
    "@type": "MedicalClinic",
    name: contactLocation.data.long_title.text,
    Description: contactLocation.data.description.text,
    Image: contactLocation.data.gallery.map(i => i.image.url),
    availableService: procedures.map(p => ({
      name: p.title,
      Description: p.body,
      url: `${process.env.GATSBY_BASE_URL}${p.slug}`,
    })),

    employee: teamMemberList.map(tm => ({
      name: tm.data.name.text,
      image: tm.data.profile_image.url,
      url: `${process.env.GATSBY_BASE_URL}/${teamMemberPage.uid}/${tm.uid}/`,
    })),
    location: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressLocality: contactLocation.data.city.text,
        addressRegion: contactLocation.data.city.text,
        streetaddress: contactLocation.data.address.text,
      },
    },
    telephone: "0319300",
    hasmap: `https://www.google.com/maps/place/@${contactLocation.data.coordinates.latitude},${contactLocation.data.coordinates.longitude},17z`,
    openingHours: ["Luni-Vineri 09:00-16:30"],
    specialOpeningHoursSpecification: "Program Infarct - 24/7",
  })

  return schema
}

const ContactLocation = ({
  data: {
    proceduresPages,
    infoPages,
    locations,
    contactLocation,
    teamMemberPage,
    guides,
    guidesPage
  },
  pageContext: { locale, breadcrumbs },
  location,
}) => {
  const classes = useStyles()

  const [selectedTeamMemberUid, setSelectedTeamMemberUid] = useState("-")
  const [selectedHelperUid, setSelectedHelperUid] = useState("-")
  const [selectedContactLocationUid, setSelectedContactLocationUid] =
    useState(contactLocation.uid)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [openGuides, setOpenGuides] = useState(false)
  const [openInfo, setOpenInfo] = useState(false)
  const [openInfoCAS, setOpenInfoCAS] = useState(false)

  const toggleOpenGuides = () => {
    setOpenGuides(!openGuides)
  }

  const toggleOpenInfo = () => {
    setOpenInfo(!openInfo)
  }

  const toggleOpenInfoCAS = () => {
    setOpenInfoCAS(!openInfoCAS)
  }

  const photos = contactLocation.data.gallery
    .filter(p => p.image.gatsbyImageData)
    .map(photo => {
      photo.uniqueId = shortid.generate()

      return photo
    })

  const showCASList = contactLocation?.data?.prices?.url && contactLocation?.data?.cas_info_link

  const proceduresPage = proceduresPages.edges.map(r => r.node).pop()
  const infoPage = infoPages.edges.map(r => r.node).pop()

  const locationList = locations.edges.map(r => r.node)
  const guideList = guides.edges.map(r => r.node).map(g => {
    if (!g.data.position) g.data.position = 0
    return g
  }).sort((a, b) => parseInt(a.data.position) - parseInt(b.data.position))

  const procedures = contactLocation.data.procedures
    .filter(({ procedure: p }) => p.document)
    .map(({ procedure: p }) => {
      let body = ""

      p.document.data.body1
        .filter(b => b.id)
        .forEach(b => {
          body = `${body} ${b.primary.content.text}`
        })

      return {
        title: p.document.data.title.text,
        body: `${body.trim().substring(0, 200)}...`,
        slug: `/${proceduresPage.uid}/${p.document.data.categories[0].category.document.uid}/${p.document.uid}/`,
        icon: p.document.data.categories[0].category.document.data.icon,
      }
    })
    .slice(0, 10)

  const teamMemberList = contactLocation.data.team_members
    .filter(({ team_member: tm }) => tm.document)
    .map(({ team_member: tm }) => tm.document)
    .slice(0, 10)

  const helperList = contactLocation.data.helpers
    .filter(({ helper: h }) => h.document)
    .map(({ helper: h }) => h.document)
    .slice(0, 10)

  const infoList = contactLocation.data.info_items
    .filter(({ info: i }) => i.document)
    .map(({ info: i }) => i.document)


  const departmentList = contactLocation.data.departments
    .filter(({ department: d }) => d.document)
    .map(({ department: d }) => d.document)

  const onSelectTeamMember = teamMemberUid => {
    setSelectedTeamMemberUid(teamMemberUid)
    setContactModalOpen(true)
  }

  const onSelectHelper = helperUid => {
    setSelectedHelperUid(helperUid)
    setContactModalOpen(true)
  }

  const onSelectContactLocation = contactLocationUid => {
    setSelectedContactLocationUid(contactLocationUid)
    setContactModalOpen(true)
  }

  return (
    <>
      <SEO
        schema={getSchema(
          contactLocation,
          procedures,
          teamMemberList,
          teamMemberPage
        )}
        ogp={contactLocation.data.ogp_tags || ""}
        meta={{
          title: contactLocation.data.meta_title,
          description: contactLocation.data.meta_description,
          keywords: contactLocation.data.meta_keywords,
          author: "Medicina Interventionala",
        }}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <SectionTitle component="h1">
                <span style={{ fontWeight: 700 }}>
                  {contactLocation.data.long_title.text}
                </span>
              </SectionTitle>
            </Grid>
            {contactLocation.data.accreditation_available && <Grid item xs={12}>
              <div className={classes.logosWrapper}>
                <img
                  src={logoANCMS}
                  alt="Unitate medicala acreditata ANMCS"
                  height="100"
                  style={{ marginRight: 20 }}
                />
                <img
                  src={logoCNAS}
                  alt="Unitate medicala acreditata CNAS"
                  height="80"
                />
              </div>
            </Grid>}

            <Grid item xs={12}>
              <Embed
                url={contactLocation.data?.virtual_tour?.embed_url}
                title={contactLocation.data?.long_title?.text}
              />
              <br />

              {photos.length > 0 && <VerticalGallery photos={photos} />}
              <br />
              <Typography
                component="div"
                dangerouslySetInnerHTML={{
                  __html: contactLocation.data.description.html,
                }}
              />

              {contactLocation.data.show_contact_form === false ? <ContactBanner redirectExternal={`tel:${contactLocation.data.phone_number}`} data={contactLocation.data} location={location} locale={locale} /> :
                <div className={classes.wideContact}>
                  <SchedulerContact
                    location={location}
                    locale={locale}
                    teamMembers={teamMemberList}
                    selectedContactLocationUid={contactLocation.uid}
                    selectedHelperUid={selectedHelperUid} />
                </div>}
            </Grid>

            {departmentList.length > 0 && (<Grid item xs={12}>
              <Typography component="h2" variant="h2">
                Departamente în {contactLocation.data.long_title.text}
              </Typography>
              <Typography component="div"
                dangerouslySetInnerHTML={{
                  __html: contactLocation.data.departments_description.html,
                }}
              />
              {departmentList.map(department => (
                <Paper
                  className={classes.buttonWrapper}
                  key={shortid.generate()}
                  elevation={1}
                  square
                >
                  <ButtonBase
                    focusRipple
                    className="departmentsButton"
                    component={Link}
                    to={`${localizedSlugPrefix(contactLocation)}/${department.data.link
                      }`}
                  >
                    <div
                      className={classes.icon}
                      style={{
                        backgroundImage: `url(${department.data.icon.gatsbyImageData.images.fallback.src})`,
                      }}
                    >
                      Icon
                    </div>
                    <Typography className={classes.headingText}>
                      {department.data.title.text}
                    </Typography>
                  </ButtonBase>
                </Paper>
              ))}
            </Grid>
            )}

            <br />
            <Grid item xs={12}>
              {procedures.length > 0 &&
                <>
                  <SectionTitle component="h2">
                    Proceduri efectuate la{" "}
                    <span style={{ fontWeight: 700 }}>
                      {contactLocation.data.title.text} -{" "}
                      {contactLocation.data.city.text}
                    </span>
                  </SectionTitle>
                  <br />
                  <ProceduresCarousel procedures={procedures} />
                  <br />
                </>}

              {teamMemberList.length > 0 && <>
                <SectionTitle component="h2">
                  Medici în{" "}
                  <span style={{ fontWeight: 700 }}>
                    {contactLocation.data.long_title.text}
                  </span>
                </SectionTitle>
                <br />
                <TeamMembersCarousel
                  teamMembers={teamMemberList}
                  onSelect={onSelectTeamMember}
                />
                <br />
              </>}

              {contactLocation.data.show_helpers !== false && helperList.length > 0 && <>
                <SectionTitle component="h2">
                  MONZA ARES Helper în{" "}
                  <span style={{ fontWeight: 700 }}>
                    {contactLocation.data.long_title.text}
                  </span>
                </SectionTitle>
                <br />
                <HelpersCarousel
                  helpers={helperList}
                  onSelect={onSelectHelper}
                />
                <br />
              </>}



              {infoList.length > 0 && (
                <div className={classes.accordion}>
                  <SectionTitle component="h2" onClick={toggleOpenInfo} style={{ cursor: 'pointer' }}>
                    MONZA ARES Info

                    {openInfo ? (
                      <ArrowUpIcon
                        style={{ fontSize: 22, height: 38, marginRight: 5, float: 'right' }}
                      />
                    ) : (
                      <ArrowDownIcon
                        style={{ fontSize: 22, height: 38, marginRight: 5, float: 'right' }}
                      />
                    )}
                  </SectionTitle>
                  <Collapse in={openInfo} timeout="auto" unmountOnExit>
                    <Typography component="div"
                      dangerouslySetInnerHTML={{
                        __html: contactLocation.data.info_description.html,
                      }}
                    />
                    <br />
                    {infoList.map((info) => (
                      <Paper
                        className={classes.buttonWrapper}
                        key={shortid.generate()}
                        elevation={1}
                        square
                      >
                        <ButtonBase
                          focusRipple
                          className="departmentsButton"
                          component={Link}
                          to={`${localizedSlugPrefix(contactLocation)}/${infoPage.uid}/${info.uid
                            }`}
                        >
                          <Typography className={classes.headingText}>
                            {info.data.title.text}
                          </Typography>
                        </ButtonBase>
                      </Paper>
                    ))}
                  </Collapse>
                </div>
              )}

              {guideList.length > 0 && (
                <div className={classes.accordion}>
                  <SectionTitle component="h2" onClick={toggleOpenGuides} style={{ cursor: 'pointer' }}>
                    Ghiduri pacienți

                    {openGuides ? (
                      <ArrowUpIcon
                        style={{ fontSize: 22, height: 38, marginRight: 5, float: 'right' }}
                      />
                    ) : (
                      <ArrowDownIcon
                        style={{ fontSize: 22, height: 38, marginRight: 5, float: 'right' }}
                      />
                    )}
                  </SectionTitle>
                  <Collapse in={openGuides} timeout="auto" unmountOnExit>
                    <div className={classes.guideList}>
                      {guideList.map((guide) => (
                        <Paper
                          className={classes.buttonWrapper}
                          key={shortid.generate()}
                          elevation={1}
                          square
                        >
                          <ButtonBase
                            focusRipple
                            className="departmentsButton"
                            component={Link}
                            to={`${localizedSlugPrefix(contactLocation)}/${guidesPage.uid}/${guide.uid}/`}
                          >
                            <div
                              className={classes.icon}
                              style={{
                                backgroundImage: `url("${guide.data.icon.gatsbyImageData?.images.fallback.src}")`,
                              }}
                            >
                              Icon
                            </div>
                            <Typography className={classes.headingText}>
                              {guide.data.title.text}
                            </Typography>
                          </ButtonBase>
                        </Paper>
                      ))}
                    </div>
                  </Collapse>
                </div>
              )}

              {showCASList && (
                <div className={classes.accordion}>
                  <SectionTitle component="h2" onClick={toggleOpenInfoCAS} style={{ cursor: 'pointer' }}>
                    Informații CAS

                    {openInfoCAS ? (
                      <ArrowUpIcon
                        style={{ fontSize: 22, height: 38, marginRight: 5, float: 'right' }}
                      />
                    ) : (
                      <ArrowDownIcon
                        style={{ fontSize: 22, height: 38, marginRight: 5, float: 'right' }}
                      />
                    )}
                  </SectionTitle>
                  <Collapse in={openInfoCAS} timeout="auto" unmountOnExit>
                    <div className={classes.guideList}>

                      {contactLocation.data?.prices?.url && <Paper
                        className={classes.buttonWrapper}
                        key={shortid.generate()}
                        elevation={1}
                        square
                      >
                        <ButtonBase
                          focusRipple
                          className="departmentsButton"
                          component="a"
                          href={contactLocation.data?.prices?.url}
                          download
                          target="_blank"
                        >
                          <Typography className={classes.headingText}>
                            Descarcă tarife CAS
                          </Typography>
                        </ButtonBase>
                      </Paper>}
                      {contactLocation.data?.cas_info_link && <Paper
                        className={classes.buttonWrapper}
                        key={shortid.generate()}
                        elevation={1}
                        square
                      >
                        <ButtonBase
                          focusRipple
                          className="departmentsButton"
                          component="a"
                          href={contactLocation.data?.cas_info_link}
                          target="_blank"
                        >
                          <Typography className={classes.headingText}>
                            Informații suplimentare CAS
                          </Typography>
                        </ButtonBase>
                      </Paper>}
                    </div>
                  </Collapse>
                </div>
              )}

              <Typography component="h2" variant="h2">
                Unde ne găsești
              </Typography>
              <Typography component="div"
                dangerouslySetInnerHTML={{
                  __html: contactLocation.data.coordinates_description.html,
                }}
              />
              <LocationMapCard
                location={contactLocation}
                onSelect={onSelectContactLocation}
              />

              <br />

              <Grid container direction="row" spacing={2}>
                <Hidden xsDown>
                  {locationList.map(location => (
                    <Grid item xs={4} key={location.uid}>
                      <ContactLocationCard
                        location={location}
                        onSelect={onSelectContactLocation}
                      />
                    </Grid>
                  ))}
                </Hidden>
                <Hidden smUp>
                  {locationList.map(location => (
                    <Grid item xs={12} key={location.uid}>
                      <LocationMapCard
                        showLinkButton
                        location={location}
                        onSelect={onSelectContactLocation}
                        showMap={false}
                        showSchedule={false}
                      />
                    </Grid>
                  ))}
                </Hidden>
              </Grid>

              <br />
              {contactLocation.data.fiscal_description.html && <Typography component="div" className={classes.fiscalDescription} dangerouslySetInnerHTML={{
                __html: contactLocation.data.fiscal_description.html,
              }} />}
            </Grid>
          </Grid>
        </Container>
        <ContactPopup
          locale={locale}
          location={location}
          open={contactModalOpen}
          handleClose={() => setContactModalOpen(false)}
          selectedContactLocationUid={selectedContactLocationUid}
          selectedTeamMemberUid={selectedTeamMemberUid}
        />
      </main>
    </>
  )
}

export default ContactLocation

export const query = graphql`
  query ContactLocationQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    proceduresPages: allPrismicProcedures(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title
          }
        }
      }
    }

    infoPages: allPrismicInformation(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang          
        }
      }
    }

    locations: allPrismicContactLocation(filter: {lang: {eq: $locale}, uid: {ne: $slug}}) {
      edges {
        node {
          uid
          lang
          data {
            address {
              text
            }            
            coordinates {
              latitude
              longitude
            }
            title {
              text
            }
            city {
              text
            }
            long_title {
              text
            }
            gallery {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }

    contactLocation: prismicContactLocation(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      id
      uid
      lang
      data {
        title {
          text
        }
        long_title {
          text
        }
        accreditation_available
        show_contact_form
        show_helpers
        phone_number
        banner_text {
          html
          text
        }
        banner_text_2 {
          html
        }
        gallery {
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
            url
          }
        }
        description {
          html
          text
        }
        departments_description {
          html
          text
        }
        virtual_tour {
          embed_url
        }
        city {
          text
        }
        address {
          text
        }
        info_description {
          html
        }
        info_items {
          info {
            document {
              ... on PrismicInformationItem {
                id
                uid
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        cas_info_link
        prices {
          url        
        }
        coordinates_description {
          html
        }
        coordinates {
          latitude
          longitude
        }

        fiscal_description {
          html
          text
        }
        departments {
          department {
            document {
              ... on PrismicDepartment {
                id
                data {
                    link
                    display_image {
                      gatsbyImageData(layout: FIXED, width: 460, height: 65)
                    }
                    icon {
                      gatsbyImageData(layout: FIXED, width: 60, height: 65)
                    }
                    title {
                      text
                    }
                  }
                uid
              }
            }
          }
        }
        procedures {
          procedure {
            document {
              ... on PrismicProcedure {
                id
                uid
                data {
                  categories {
                    category {
                      document {
                        ... on PrismicProcedureCategory {
                          id
                          uid
                          data {
                            icon {
                              alt
                              gatsbyImageData(layout: FIXED, width: 150)
                            }
                          }
                        }
                      }
                    }
                  }
                  body1 {
                    ... on PrismicProcedureDataBody1Simple {
                      id
                      primary {
                        content {
                          text
                        }
                      }
                    }
                    ... on PrismicProcedureDataBody1RepeatableImageType {
                      id
                      primary {
                        content {
                          text
                        }
                      }
                    }
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        team_members {
          team_member {
            document {
              ... on PrismicTeamMember {
                id
                uid
                data {
                  name {
                    text
                  }
                  specialisations {
                    specialisation: specialization {
                      document {
                        ... on PrismicSpecialisation {
                          id
                          uid
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                  profile_image {
                    alt
                    gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
                    url
                  }
                }
              }
            }
          }
        }

        helpers {
          helper {
            document {
              ... on PrismicHelper {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  ratings {
                    field
                    value
                  }
                  specialisations {
                    specialisation: specialization {
                      document {
                        ... on PrismicSpecialisation {
                          id
                          uid
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                  profile_image {
                    alt
                    gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
                  }
                }
              }
            }
          }
        }

        schedule {
          key
          value
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }

    teamMemberPage: prismicTeam(lang: { eq: $locale }) {
      uid
    }

    guides: allPrismicGuide(
      filter: {data: {locations: {elemMatch: {location: {uid: {in: [$slug]}, , lang: {eq: $locale}}}}}}
    ) {
      edges {
        node {
          id
          uid
          data {
            position
            icon {
              gatsbyImageData(layout: FIXED, width: 60, height: 65)
            }
            title {
              text
            }
          }
        }
      }
    }

    guidesPage: prismicGuidesPage(lang: { eq: $locale }) {
      uid
    }
  }
`

ContactLocation.propTypes = {
  data: PropTypes.shape({
    proceduresPages: PropTypes.object.isRequired,
    infoPages: PropTypes.object.isRequired,
    locations: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    contactLocation: PropTypes.object.isRequired,
    teamMemberPage: PropTypes.object.isRequired,
    guides: PropTypes.object.isRequired,
    guidesPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
