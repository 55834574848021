import React from 'react'
import PropTypes from 'prop-types'

const queryString = require('query-string');

const Embed = ({ url, title }) => {
    if (/youtube/gm.test(url)) {
        return <iframe
            title={title}
            src={`https://www.youtube.com/embed/${queryString.parse(
                url.replace(
                    "https://www.youtube.com/watch?",
                    ""
                )
            ).v
                }?feature=oembed`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    } else if (/matterport/gm.test(url)) {
        return <iframe
            title={title}
            width="853"
            height="480"
            src={url}
            frameBorder="0"
            allowFullScreen
            allow="xr-spatial-tracking"
        />
    }

    return <></>
}

export default Embed

Embed.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
}
