import React, { useState } from "react"
import PropTypes from "prop-types"

import makeStyles from '@material-ui/core/styles/makeStyles'

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"


import ContactPopup from "../contacts/ContactPopup"

import "../../styles/contactBanner.scss"

const useStyles = makeStyles(theme => ({
  wrapper: {
    textAlign: "left",
    cursor: "pointer",
    fontSize: '1.5rem',
  },

  textLeft: {
    position: 'relative',
    color: theme.palette.common.white,
    display: 'flex',
    padding: theme.spacing(3),
    height: 170,
    background:
      "linear-gradient(0deg, rgba(0,101,155,1) 0%, rgba(1,102,154,1) 100%, rgba(192,215,229,1) 100%)",

    "& p": {
      margin: "auto",
      display: "inline-block",
      textAlign: "center",
      fontWeight: 700,
      fontSize: "1.5rem",
    },

    "& a": {
      color: theme.palette.common.white,
    },

    "& a:hover": {
      textDecoration: "underline",
    },

    '&::before': {
      [theme.breakpoints.up("sm")]: {
        content: '""',
        position: 'absolute',
        right: -35,
        bottom: 0,
        width: 0,
        height: 0,
        top: 0,
        borderLeft: '35px solid #0f669b',
        borderTop: '85px solid transparent',
        borderBottom: '85px solid transparent'
      },
    }
  },
  textRight: {
    color: theme.palette.common.white,
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    display: 'flex',
    height: 170,
    "& p": {
      margin: "auto",
      display: "inline-block",
      textAlign: "center",
      fontSize: "1.5rem",
    },

    "& a": {
      color: theme.palette.common.white,
    },

    "& a:hover": {
      textDecoration: "underline",
    },
  },

  innerTextRight: {
    paddingRight: 160,
    display: 'flex',
    [theme.breakpoints.up("sm")]: {
      // paddingLeft: 70
    }
  }
}))

const ContactBanner = ({ data, location, locale, redirectExternal = false }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  return (
    <div className="contactBanner">
      <Grid
        className={`${classes.wrapper} bannerWrapper`}
        container
        onClick={redirectExternal ? null : handleClickOpen}
        component={redirectExternal ? 'a' : 'div'}
        href={redirectExternal || null}
      >
        <Grid item xs={12} sm={6} className={`${classes.textLeft}`}>
          <Typography
            component='div'
            dangerouslySetInnerHTML={{
              __html: data.banner_text.html,
            }}
            style={{ display: 'flex', width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} sm={6} className={`${classes.textRight} bannerTextRight`}>
          <Typography
            component='div'
            dangerouslySetInnerHTML={{
              __html: data.banner_text_2.html,
            }}
            className={classes.innerTextRight}
          />
        </Grid>


      </Grid>
      <ContactPopup
        locale={locale}
        location={location}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </div>
  )
}

export default ContactBanner

ContactBanner.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
}
