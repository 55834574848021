import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

import clsx from "clsx"

import Grid from "@material-ui/core/Grid"

import makeStyles from "@material-ui/core/styles/makeStyles"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../styles/verticalGallery.scss"

const useStyles = makeStyles(theme => ({
  sliderWrapper: {
    overflow: "hidden",
  },

  cardWrapper: {
    height: "100%",
    "&:focus": {
      outline: "none",
    },
  },

  sliderBig: {
    height: "75vh",
    position: "relative",
  },

  largeImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    overflow: "hidden",
  },

  pager: {
    position: "absolute",
    bottom: 20,
  },

  pagerDot: {
    opacity: 1,
    width: 20,
    height: 20,
    boxShadow: "none",
    border: "1px solid white",
    background: "transparent",
    borderRadius: "50%",
    cursor: "pointer",
    margin: "0 5px",

    "&.active": {
      background: "white",
    },
  },

  galleryDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  galleryMobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}))

const VerticalGallery = ({ photos }) => {
  const classes = useStyles()

  const [currentPhoto, setCurrentPhoto] = useState(photos[0].uniqueId)

  const sliderRef = useRef(null)

  const slider = {
    dots: false,
    centerPadding: "10px",
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 500,
    focusOnSelect: true,
    slidesToShow: 2,
    vertical: true,
    verticalSwiping: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (old, newImage) => {
      setCurrentPhoto(photos[newImage].uniqueId)
    },
  }

  const sliderMobile = {
    ...slider,
    vertical: false,
    verticalSwiping: false,
    slidesToShow: 3,
  }

  const setPhoto = id => {
    sliderRef.current.slickGoTo(id)
  }

  return (
    <div>
      <Grid container spacing={1} direction="row" justifyContent="center">
        <Grid item xs={12} md={8} className={classes.sliderBig}>
          <Grid
            container
            className={classes.pager}
            justifyContent="center"
            alignItems="center"
          >
            {photos.map((photo, idx) => (
              <Grid
                key={photo.uniqueId}
                onClick={() => setPhoto(idx)}
                className={clsx(
                  currentPhoto === photo.uniqueId ? "active" : "",
                  classes.pagerDot
                )}
              />
            ))}
          </Grid>
          {photos.map(
            photo =>
              currentPhoto === photo.uniqueId && (
                <GatsbyImage
                  key={photo.uniqueId}
                  className={classes.largeImage}
                  image={photo.image.gatsbyImageData}
                  alt={photo.image.alt ?? ""}
                />
              )
          )}
        </Grid>

        <Grid
          item
          xs={4}
          className={`${clsx(
            "verticalGalleryWrapper",
            classes.galleryDesktop
          )}`}
        >
          <Grid container>
            <Grid item xs={12} className={classes.sliderWrapper}>
              <Slider {...slider} ref={sliderRef}>
                {photos.map(photo => (
                  <div className={classes.cardWrapper} key={photo.uniqueId}>
                    <GatsbyImage
                      className={classes.largeImage}
                      image={photo.image.gatsbyImageData}
                      alt={photo.image.alt ?? ""}
                    />
                  </div>
                ))}
              </Slider>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={`${clsx(
            "verticalGalleryWrapper_mobile",
            classes.galleryMobile
          )}`}
        >
          <Grid container>
            <Grid item xs={12} className={classes.sliderWrapper}>
              <Slider {...sliderMobile} ref={sliderRef}>
                {photos.map(photo => (
                  <div className={classes.cardWrapper} key={photo.uniqueId}>
                    <GatsbyImage
                      className={classes.largeImage}
                      image={photo.image.gatsbyImageData}
                      alt={photo.image.alt ?? ""}
                    />
                  </div>
                ))}
              </Slider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default VerticalGallery

VerticalGallery.propTypes = {
  photos: PropTypes.array.isRequired,
}
